<template>
	<div class="bootstrap-hva">
		<div class="container gutter-hva fix-mobile-width">
			<editorBar v-if="phase && userCanEditPhasing" :isEditing="isEditing" :isSaving="isSaving" :isDirty="isDirty" :saveMessage="saveMessage" :showCourseCatalogWarning="isExchange && programDetails.ShowInCourseCatalogOrShowInCourseCatalogEdit" @switchView="onSwitchView" @save="savePhasing" />
			<overview-header :program="programDetails" :isEditing="isEditing" />
			<div class="row">
				<div class="col-md-3">
					<side-menu class="vue-menu" :userCanEditProgram="userCanEditPhasing && isEditing" />
				</div>
				<div class="col col-md-9">
                    <div v-if="!loading">
                        <div class="acYearHeading">{{$t('programList.academicYear')}} : {{acYear}} <spinner v-if="newDataLoading" /></div>
                        <div class="overview-content phasing-content">
                            <div v-if="!isEditing" class="redit">
                                <h2>{{phase.PhasingLabel}}</h2>
                            </div>
                                <template v-if="!isEditing ">
                                    <div class="phasing-description">
                                        <div class="text-content" v-html="phase.Text"></div>
                                    </div>
                                </template>
                            <template v-if="userCanEditPhasing">
                                <div v-for="(item, index) in items" :key="index">
                                    <template v-if="isEditing">
                                        <text-item v-if="item.type === 'text'" v-bind="item" set :edit="isEditing" @change="updateSave" @dirty="onDirty" @requiredFieldNotFilled="requiredFieldNotFilled" @requiredFieldFilled="requiredFieldFilled" />
                                        <readonly v-if="item.type == 'readonly'" v-bind="item" set :edit="isEditing" />
                                    </template>
                                </div>
                            </template>
                            <div class="phasing-grid">
                                <div class="row" v-for="(rows, index) in baseitemsAsRows" :key="index">
                                    <div class="col col-md-6" v-for="row in rows" :key="row.Id">
                                        <h3 class="phasing-title">{{row.Title}}</h3>
                                        <ul class="phasing-list">
                                            <li v-for="item in row.Baseitems" :key="item.Id">
                                                <div class="phasing-course">
                                                    <div v-if="userCanEditCourse(item) && (isEditing || !userCanEditPhasing)" class="redit">
                                                        <router-link class="hva-caret-right" :to="courseRoute(item)" append>{{item.Title}}</router-link>
                                                        <div class="icons">
                                                            <font-awesome-icon v-if="!stringIsNullOrEmpty(item.Cloned)" :alt="$t('general.cloned')" :title="$t('general.cloned')" icon="clone" />
                                                            <font-awesome-icon v-if="item.CourseCatalogHasMultipleBaseItems" :alt="$t('general.sharesData')" :title="$t('general.sharesData')" icon="link" />
                                                            <font-awesome-icon :class="[ToCamelCase(item.EditorialState), 'redactiestatusOpleiding']" :alt="stateMessage(item.EditorialState)" :title="stateMessage(item.EditorialState)" icon="circle" />
                                                        </div>
                                                    </div>
                                                    <div v-else>
                                                        <router-link class="hva-caret-right" :to="courseRoute(item)" append>{{item.Title}}</router-link>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <router-link v-if="!loading" target="_blank" :to="{ name: 'print-phasing', params: { programSlug: this.$route.params.programSlug, acYear: acYear, phaseValue: this.$route.params.phaseValue}}">
                                <div class="pdfDownload download-link">{{$t("general.download")}}</div>
                                <font-awesome-icon icon="file-pdf" class="fontAwesome" />
                                <font-awesome-icon icon="print" class="fontAwesome" />
                            </router-link>
                        </div>
                    </div>
                    <spinner v-else />
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import OverviewHeader from "common/components/General/OverviewHeader.vue";
import SideMenu from "common/components/General/SideMenu.vue";
import Spinner from "common/components/General/Spinner.vue";
import EditorialState from "common/mixins/EditorialState.js";
import Overview from "../mixins/Overview.js";
import { mapState } from "vuex";
import { get, put, post, camelCase } from 'common/store/storeFunctions.js'

export default {
	components: {
		SideMenu,
		OverviewHeader,
		Spinner
	},
    mixins: [EditorialState, Overview],
    data: function() {
        return {
            newDataLoading: false
		};
	},
    computed: {
         acYear() {
            var acYear = this.$route.params.acYear;
            if (acYear == null && this.acYears) {
                acYear = this.acYears.currentAcademicYear;
            }
            return acYear;
        },
		programDetails() {
			if (this.acYear) {
                var params = this.$route.params;
                var key = this.acYear + params.programSlug;
                return this.program[key] ? this.program[key].details : null;
            } else {
                return null;
            }
		},
        programPhasings() {
            if (this.acYear) {
                var params = this.$route.params;
                var key = this.acYear + params.programSlug;
                return this.program[key] ? this.program[key].phasings : null;
            } else {
                return null;
            }
		},
        programBaseitems() {
            if (this.acYear) {
                var params = this.$route.params;
                var key = this.acYear + params.programSlug;
                var phasevalue = params.phaseValue;
                return this.program[key] && this.program[key].baseitems ? this.program[key].baseitems[phasevalue] : null;
            } else {
                return null;
            }
		},
		baseitemsAsRows() {
			if (this.programBaseitems) {
				var rows = [];
				for (var i = 0; i < this.programBaseitems.length; i += 2) {
					var row = [];
					row.push(this.programBaseitems[i]);
					if (this.programBaseitems[i + 1]) {
						row.push(this.programBaseitems[i + 1]);
					}
					rows.push(row);
                }
                var model = this;
                for (var j = 0; j < rows.length; j++) {
                    for (var h = 0; h < rows[j].length; h++) {
                        if (rows[j][h].Baseitems) {
                            rows[j][h].Baseitems.sort(function (a, b) {
                                return model.CompareBaseItems(a, b);
                            });
                        }
                    }
                }
				return rows;
			}
			return [];
		},
		phase() {
			if (this.programPhasings) {
				var phaseValue = this.identifyingProperty ? this.identifyingProperty : this.$route.params.phaseValue;
				for (var i in this.programPhasings) {
					var p = this.programPhasings[i];
					var null_id = "00000000-0000-0000-0000-000000000000";
					if (p.PhasingValue == phaseValue) {
						if (p.Id == null_id) p.Id = null;
						return p;
					}
				}
			}
			return null;
		},
		loading() {
			return this.programDetails == null || this.programPhasings == null || this.programBaseitems == null;
		},
        userCanEditPhasing() {
            if (this.programDetails) {
                var facultyId = this.programDetails.FacultyId;
                var facultyProgramEditor = false;
                if (this.isExchange && ((this.roles.ExchangeProgramProgramEditor && this.roles.ExchangeProgramProgramEditor.indexOf(facultyId) != -1) || (this.roles.ExchangeProgramProgramFinalEditor && this.roles.ExchangeProgramProgramFinalEditor.indexOf(facultyId) != -1))) {
                    facultyProgramEditor = true;
                }
                if (!this.isExchange && ((this.roles.CourseCatalogProgramEditor && this.roles.CourseCatalogProgramEditor.indexOf(facultyId) != -1) || (this.roles.CourseCatalogProgramFinalEditor && this.roles.CourseCatalogProgramFinalEditor.indexOf(facultyId) != -1))) {
                    facultyProgramEditor = true;
                }
                return facultyProgramEditor && this.programDetails.Editable && (this.userIsProgramEditor || this.userIsProgramFinalEditor);
            } else {
                return false;
            }
		},
		userCanEditCourses(){
			return  (this.userIsCourseEditor || this.userIsCourseFinalEditor);
		},
		...mapState(["program", "acYears"])
	},
	watch:{
		phase: function(){
			this.populateData();
		},
		userCanEditPhasing: async function(newValue){
			var model = this;
			if(newValue){
				model.phasingOptions = await get(`/api/phasinginfo/getoptions/${model.$i18n.locale == 'nl' ? 1043 : 1033}`)
			}
		}
	},
	async created() {
        var model = this;
        var params = this.$route.params;
        this.$store.dispatch("getProgram", params);
        this.newDataLoading = true;
		this.$store.dispatch("getBaseitems", params).then(function(){
            model.newDataLoading = false;
        });
		this.populateData();
	},
	methods: {
		populateData(){
			var model = this;
			if(this.phase === undefined || this.phase === null) return;
            this.isEditing = this.userCanEditPhasing;
			this.items = {
				"commonLabels.phasing": model.createReadOnly(model.phase, "commonLabels.phasing", "PhasingLabel", true),
				"phasingPageLabels.description": model.createText(model.phase, "phasingPageLabels.description", "Text", false, model.programDetails.ShowInEnglish ? 'en_GB' : null)
			}
		},
		userCanEditCourse(baseItem){
			if(baseItem === undefined || baseItem === null){
				return false;
            }
            var facultyId = baseItem.FacultyId;
			var facultyBaseItemEditor = false;
			if (this.isExchange && ((this.roles.ExchangeProgramCourseEditor && this.roles.ExchangeProgramCourseEditor.indexOf(facultyId) != -1) || (this.roles.ExchangeProgramCourseFinalEditor && this.roles.ExchangeProgramCourseFinalEditor.indexOf(facultyId) != -1))){
				facultyBaseItemEditor = true;	
			}
			if (!this.isExchange && ((this.roles.CourseCatalogCourseEditor && this.roles.CourseCatalogCourseEditor.indexOf(facultyId) != -1) || (this.roles.CourseCatalogCourseFinalEditor && this.roles.CourseCatalogCourseFinalEditor.indexOf(facultyId) != -1))){
				facultyBaseItemEditor = true;				
            }
			return facultyBaseItemEditor && baseItem.Editable && this.userCanEditCourses
		},
		courseRoute(baseitem) {
            var params = this.$route.params;
            if (params.acYear) {
                return {
                    name: "course-overview",
                    params: {
                        programSlug: params.programSlug,
                        phaseValue: params.phaseValue,
                        baseItemId: baseitem.BaseItemId.substring(4),
                        acYear: params.acYear
                    }
                };
            } else {
                return {
                    name: "course-overview",
                    params: {
                        programSlug: params.programSlug,
                        phaseValue: params.phaseValue,
                        baseItemId: baseitem.BaseItemId.substring(4)
                    }
                };
            }
		},
		async savePhasing(){
            var model = this;
            var params = this.$route.params;
            var key = this.acYear + params.programSlug;
			var updateKey = `program.${key}.phasings`;
			model.isSaving = true;
			try {
				if(this.phase.Id){
					await put("/api/phasinginfo/update", model.phase);
				} else {
					var programId = model.programDetails.Id;
					await post(`/api/phasinginfo/create/${programId}`, model.phase);
				}
				model.saveMessage.type = "";
			} catch {
				model.saveMessage.type = "updateError";	
			}
			
			this.$store.commit("resetCacheForKey", updateKey);
			this.$store.dispatch("getPhasing", this.$route.params);
			model.isSaving = false;
        },
        CompareBaseItems(ob1, ob2) {
            if (ob1.SortId === null && ob2.SortId !== null) return 1;
            else if (ob1.SortId !== null && ob2.SortId === null) return -1;

            if (ob1.SortId > ob2.SortId) {
                return 1;
            } else if (ob1.SortId < ob2.SortId) {
                return -1;
            }

            if (ob1.Title < ob2.Title) {
                return -1;
            } else if (ob1.Title > ob2.Title) {
                return 1;
            } else {
                return 0;
            }
        },
        ToCamelCase(item){
            return camelCase(item);
        }
	}
};
</script>
<style lang="less" scoped>
#app {
	.icons {
		width: 20%;
		display: flex;
		justify-content: flex-end;
    }
    .acYearHeading .spinner {
        margin-top: 0px;
        justify-content: unset;
        display: inline-flex;
        height: 30px;
        width: 40px;
        margin-left:10px;
        margin-bottom: -6px;
    }
}
</style>
<style lang="less">
@import "../assets/overview.less";
</style>
